import Image, { StaticImageData } from 'next/image';
import React from 'react';

import styles from './style.module.scss';

export interface Props {
  className?: string;
  title: string;
  description: string;
  img: { src: string | StaticImageData; alt: string };
}

const ImageCard = ({ title, description, img, className = '' }: Props) => (
  <div className={`ta-center br8 ${styles.container} ${className}`}>
    <div className={styles.imageContainer}>
      <Image src={img.src} alt={img.alt} layout="fill" objectFit="cover" />
    </div>
    <div className="p24">
      <div className={styles.textOverflowContainer}>
        <div className="tc-grey-900 p-h3">{title}</div>
        <div className="p-p tc-grey-600 mt16">{description}</div>
      </div>
    </div>
  </div>
);

export default ImageCard;
