import type { ReviewContent } from 'components/reviewCarousel/content/reviews';
import Image from 'next/image';
import quoteIcon from 'public/static/icons/quote.svg';
import React from 'react';

import styles from './style.module.scss';

const ReviewDetails = ({ review, title, url, consumer }: ReviewContent) => (
  <a
    className={`mr16 wmn4 ${styles.container}`}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className={`p32 bg-grey-100 br8 ${styles.quoteContainer}`}>
      <Image width={32} src={quoteIcon} alt="quote icon" />
      <div className="mt24 p-h4 tc-primary-900">{title}</div>
      <div className="p-p tc-grey-600 mt8">“{review}”</div>
      <div className="mt24 d-flex">
        <div className="p-p--small fw-bold tc-grey-600">{consumer}</div>
      </div>
    </div>
  </a>
);

export default ReviewDetails;
