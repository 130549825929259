import { app, blog } from '@getpopsure/private-constants';
import ImageCard from 'components/imageCard';
import LinkTo from 'components/linkTo';
import ReviewCarousel from 'components/reviewCarousel';
import { getReviews } from 'components/reviewCarousel/content/reviews';
import {
  ENGLISH_LOCALE_IDENTIFIER,
  GERMAN_LOCALE_IDENTIFIER,
} from 'constants/i18n';
import { productLinks } from 'constants/internalLinks';
import images from 'content/homepage/images';
import type {
  TVerticalGroupLookup,
  TVerticalLookup,
} from 'content/policy-links/products';
import { groupedVerticals } from 'content/policy-links/products';
import { VerticalType } from 'models/product';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import type { BlogPostType } from 'pages/index.page';
import europeanUnionImage from 'public/static/home/security-section/eu.svg';
import gdprImage from 'public/static/home/security-section/gdpr.svg';
import { useRef } from 'react';
import { goToSection } from 'util/goToSection';

import BannerCard from './components/BannerCard';
import styles from './styles.module.scss';

const htmlTagRegex = /(<([^>]+)>)/gi;
const apostropheHtmlEntity = '&#8217;';

export const Homepage = ({
  navOffset,
  blogPosts,
  germanPaths,
}: {
  blogPosts: BlogPostType[];
  navOffset?: number;
  germanPaths: Array<string>;
}) => {
  const productGridRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const router = useRouter();
  const isLocaleGerman = router.locale === GERMAN_LOCALE_IDENTIFIER;

  const imgLocaleKey = isLocaleGerman
    ? GERMAN_LOCALE_IDENTIFIER
    : ENGLISH_LOCALE_IDENTIFIER;

  const germanProductKeys = germanPaths
    .map((link) =>
      Object.keys(productLinks).find(
        (key) => productLinks[key as VerticalType] === link
      )
    )
    .filter((value) => !!value);

  const getGermanVerticals = (verticalGroups: TVerticalGroupLookup[]) =>
    verticalGroups
      .map((group) => ({
        ...group,
        items: group.items.filter((item) =>
          germanProductKeys.includes(item.key)
        ),
      }))
      .filter((group) => group.items.length > 0); // remove group with no product

  const productGrids = isLocaleGerman
    ? getGermanVerticals(groupedVerticals(t))
    : groupedVerticals(t);

  const reviewContent = getReviews(t);

  return (
    <div className={styles.root}>
      <div className="mt72 bg-grey-100">
        <section className="p-body">
          <div className={`${styles.heroSection}`}>
            <div className="">
              <h1 className="p-h1 p-h1--xl p--serif tc-grey-700">
                {t('home:section.1.title', 'Honest, simple insurance.')}
              </h1>
              <p className="p-p mt16 tc-grey-700">
                {t(
                  'home:herosection.description',
                  'Just tell us a bit about yourself, and we’ll let you know which plans are right for you!'
                )}
              </p>
              <button
                className="p-btn p-btn--primary wmn3 mt24"
                type="button"
                onClick={() => goToSection(productGridRef, navOffset)}
              >
                {t('home:herosection.action', 'See our policies')}
              </button>
            </div>
            <div className={`${styles.heroSectionImageWrapper} pt96 pb96 ml48`}>
              <NextImage
                src={images.hero[imgLocaleKey]}
                width={516}
                alt="an office kitchen which includes one woman working with her laptop and there is one man looking at the screen of her laptop"
                priority={true}
              />
            </div>
          </div>
        </section>
      </div>
      <div className={`mt88 ${styles.topSectionWrapper}`}>
        <section className="p-body">
          <BannerCard
            title={t('home:topsection.1.title', 'Reliable support')}
            description={
              <Trans
                i18nKey="home:topsection.1.description"
                defaultValue="<0>Whether you need help adding a family member to your plan, or want information about other plans, or want to know more about <2>student health insurance</2> in Germany — our customer support team is available to chat over a call or through email.</0>"
              >
                <span>
                  Whether you need help adding a family member to your plan, or
                  want information about other plans, or want to know more about{' '}
                  <LinkTo className="p-a" href="/student-health-insurance">
                    student health insurance
                  </LinkTo>{' '}
                  in Germany — our customer support team is available to chat
                  over a call or through email.
                </span>
              </Trans>
            }
            image={{
              src: images.topFirst[imgLocaleKey],
              alt: 'a smiling guy sitting on a sofa with his laptop on his lap',
              width: 364,
              height: 288,
            }}
          />
          <BannerCard
            title={t('home:topsection.2.title', 'Transparent advice')}
            description={
              <Trans
                i18nKey="home:topsection.2.description"
                defaultValue="<0>Can an insurance company really offer transparent advice? Absolutely! That’s why we’ve taken the extra step to change our <2>incentive structures</2>. Our team’s success is based on customer satisfaction rather than selling plans.</0>"
              >
                <span>
                  Can an insurance company really offer transparent advice?
                  Absolutely! That’s why we’ve taken the extra step to change
                  our{' '}
                  <a
                    className="p-a"
                    href="https://blog.feather-insurance.com/feather-who-we-are-what-we-stand-for-and-why-we-care/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    incentive structures
                  </a>
                  . Our team’s success is based on customer satisfaction rather
                  than selling plans.
                </span>
              </Trans>
            }
            image={{
              src: images.topSecond[imgLocaleKey],
              alt: 'a woman sits on a couch and a man showing his ipad while he is on his knees',
              width: 380,
              height: 300,
            }}
            reverseLayout
          />
          <BannerCard
            title={t('home:topsection.3.title', 'Everything under one account')}
            description={t(
              'home:topsection.3.description',
              'You can manage all of your insurance plans from the same account. That includes signing up for new plans, submitting claims, and canceling the ones you don’t need anymore.'
            )}
            image={{
              src: images.topThird[imgLocaleKey],
              alt: 'a phone which shows Feather Insurance app with household, expat and legal insurance icons diplayed.',
              width: 400,
              height: 288,
            }}
          />
        </section>
      </div>
      <section className="p-body pt80" ref={productGridRef} id="insurances">
        <h2 className="p-h1 p--serif ta-center">
          {t('home:productgrid.title', 'The first insurance companion')}
        </h2>
        <p className="wmx7 p-p mx-auto mt8 ta-center tc-grey-600">
          {t(
            'home:productgrid.description',
            'Our goal is to guide you through the complex world of insurance while taking care of the hard parts for you — because, let’s face it, no one wants to deal with insurance documents alone.'
          )}
        </p>
        <div>
          {productGrids.map((verticalGroup) => (
            <div key={verticalGroup.key}>
              <h3 className="d-flex flex-wrap ai-center p-h4 mt24 mb16 tc-grey-600">
                <NextImage
                  className="mr8"
                  src={verticalGroup.icon}
                  alt={verticalGroup.title}
                />
                {verticalGroup.title}
              </h3>
              <div className={styles.productGridItems}>
                {verticalGroup.items.map((vertical: TVerticalLookup) => (
                  <LinkTo
                    href={vertical.key ? productLinks[vertical.key] : ''}
                    aria-label={vertical.shortTitle}
                    key={vertical.shortTitle}
                    className="p-a"
                  >
                    <div
                      className={`${styles.verticalItem} bg-grey-100 p24 br8 mb16`}
                    >
                      <div className={styles.verticalItemImage}>
                        <NextImage
                          className="br8"
                          width={72}
                          height={72}
                          src={vertical.icon}
                          alt={vertical.title}
                        />
                      </div>
                      <div className={styles.verticalItemText}>
                        <h4 className="p-h4 mt16 tc-grey-900">
                          {vertical.shortTitle}
                        </h4>
                        <p className="p-p--small tc-grey-600">
                          {vertical.excerpt}
                        </p>
                      </div>
                    </div>
                  </LinkTo>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div
          className={`${styles.recToolCard} bg-grey-100 p24 mt56 br8 d-grid`}
        >
          <div className={styles.recToolCardText}>
            <h2 className="p-h2 tc-grey-900">
              {t(
                'home:rectool.cta.title',
                'Want to test our transparent advice?'
              )}
            </h2>
            <p className="p-p mt8 tc-grey-700">
              {t(
                'home:rectool.cta.description',
                'Our recommendation tool doesn’t require your email, phone number, or payment information to get honest and simple insurance advice.'
              )}
            </p>
            <LinkTo
              href={app.recommendationTool}
              className="mt24 p-btn p-btn--primary"
            >
              {t('home:rectool.cta.button', 'Get a recommendation')}
            </LinkTo>
          </div>
          <div className={styles.recToolCardImage}>
            <NextImage
              src={images.recTool[imgLocaleKey]}
              alt="Recommendation Tool shown in a phone screen"
              width="304"
              height="220"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="p-body">
          <ReviewCarousel reviewContent={reviewContent} />
        </div>
      </section>
      {!isLocaleGerman && (
        <section className="pt96">
          <div className="p-body">
            <header>
              <h2 className="p-h2 pt96 p--serif ta-center">
                {t('home:section.blogposts.title', 'Our latest articles')}
              </h2>
            </header>
            <div
              data-aos="animate-underline"
              data-aos-once="true"
              className={`mt8 ml-auto mr-auto ${styles.separator}`}
            />
            <div
              className={`mt32 pb32 d-flex f-wrap jc-between ${styles.sectionNewsContainer}`}
            >
              {blogPosts &&
                blogPosts.length &&
                blogPosts.map(({ node: item }) => (
                  <a
                    href={item.link}
                    key={item.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ImageCard
                      className="wmx4"
                      title={item.title.replace(htmlTagRegex, '')}
                      description={item.excerpt
                        .replace(htmlTagRegex, '')
                        .replace(apostropheHtmlEntity, "'")}
                      img={{
                        src: item.featuredImage?.node.sourceUrl || '',
                        alt: item.featuredImage?.node.altText || '',
                      }}
                    />
                  </a>
                ))}
            </div>
            <div className="ta-center mb72">
              <a className="p-btn p-btn--primary" href={blog.base}>
                {t('home:section.blogposts.readmore', 'Read more')}
              </a>
            </div>
          </div>
        </section>
      )}
      <section
        className={`${styles.sectionDataSecurity} p-body d-flex f-wrap mb32 pt96`}
      >
        <div
          className={`${styles.dataSecurityColumn} d-inline-flex wmx4 fd-column p24 mb8`}
        >
          <h3 className="p-h3 tc-grey-900">
            {t('home:section.security.title', 'Data security')}
          </h3>
          <p className="p-p mt8 tc-grey-600">
            {t(
              'home:section.security.description',
              'Simple insurance doesn&apos;t mean it shouldn&apos;t be safe.'
            )}
          </p>
        </div>
        <div className="d-inline-flex wmx4 fd-column bg-grey-100 br12 p24 mb8">
          <div className="d-flex ai-start">
            <NextImage
              width={32}
              src={europeanUnionImage}
              alt="european union logo"
            />
            <div className="ml24">
              <h3 className="p-h3 tc-grey-900">
                {t(
                  'home:section.security.serversineu.title',
                  'Servers in the EU'
                )}
              </h3>
              <p className="p-p--small mt8 tc-grey-600">
                {t(
                  'home:section.security.serversineu.description',
                  'User data is processed in our app and isn&#8217;t being accessed by third parties.'
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="d-inline-flex wmx4 fd-column bg-grey-100 br12 p24 mb8">
          <div className="d-flex ai-start">
            <NextImage width={32} src={gdprImage} alt="GDPR" />
            <div className="ml24">
              <h3 className="p-h3 tc-grey-900">
                {t(
                  'home:section.security.gdprcompliant.title',
                  'GDPR compliant'
                )}
              </h3>
              <p className="p-p--small mt8 tc-grey-600">
                {t(
                  'home:section.security.gdprcompliant.description',
                  'Feather only collects and stores necessary data according to Europe&#8217;s data privacy laws.'
                )}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
