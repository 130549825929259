import classnames from 'classnames';
import LegacyImage, { StaticImageData } from 'next/legacy/image';

import styles from './BannerCard.module.scss';

interface BannerCardProps {
  title: string;
  description: string | JSX.Element;
  image: {
    src: StaticImageData | string;
    alt: string;
    width?: number;
    height?: number;
  };
  reverseLayout?: boolean;
}

const BannerCard = ({
  title,
  description,
  image: { src, alt, width, height },
  reverseLayout = false,
}: BannerCardProps) => {
  return (
    <div
      className={classnames(`d-grid ${styles.bannerCard}`, {
        [styles.bannerCardReverse]: reverseLayout,
      })}
    >
      <div className={styles.bannerCardImage}>
        <LegacyImage
          src={src}
          alt={alt}
          width={width}
          height={height}
          loading="eager"
        />
      </div>
      <div className={styles.bannerCardText}>
        <div>
          <div className="p-h2 p--serif">{title}</div>
          <p className="p-p mt16 tc-grey-600">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default BannerCard;
