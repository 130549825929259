import classNames from 'classnames';
import { AggregateRating } from 'next-seo/lib/types';
import React from 'react';

import GoogleReviewBadge, { googleReviews } from './googleReviewBadge';
import styles from './style.module.scss';
import TrustpilotReviewBadge, {
  trustpilotReviews,
} from './trustpilotReviewBadge';

interface ReviewBadgeProps {
  className?: string;
  displayReviews?: boolean;
}

export const featherAggregatedRating: AggregateRating = {
  ratingValue: `${(trustpilotReviews.rating + googleReviews.rating) / 2}`,
  ratingCount: `${
    trustpilotReviews.numberOfReviews + googleReviews.numberOfReviews
  }`,
  bestRating: '5',
};

const ReviewBadge = ({ className, displayReviews }: ReviewBadgeProps) => (
  <div className={classNames(styles.container, className)}>
    <div className={styles['review-rating']}>
      <GoogleReviewBadge displayReviews={displayReviews} />
    </div>
    <TrustpilotReviewBadge displayReviews={displayReviews} />
  </div>
);

export default ReviewBadge;
