import { ExtendedVerticalType, VerticalType } from 'models/product';
import { StaticImageData } from 'next/image';
import { TFunction } from 'next-i18next';
import companyHealthIcon from 'public/static/components/policyLink/company-health.jpg';
import companyPensionIcon from 'public/static/components/policyLink/company-pension.jpg';
import companyTravelIcon from 'public/static/components/policyLink/company-travel.jpeg';
import basicsCategoryIcon from 'public/static/home/product-section/icons/basics.svg';
import healthCategoryIcon from 'public/static/home/product-section/icons/health.svg';
import lifeCategoryIcon from 'public/static/home/product-section/icons/life.svg';
import bikeIcon from 'public/static/home/product-section/img/bike.jpg';
import dentalIcon from 'public/static/home/product-section/img/dental.jpg';
import disabilityIcon from 'public/static/home/product-section/img/disability.jpg';
import dogIcon from 'public/static/home/product-section/img/dog.jpg';
import expatIcon from 'public/static/home/product-section/img/expat.jpg';
import householdIcon from 'public/static/home/product-section/img/household.jpg';
import legalIcon from 'public/static/home/product-section/img/legal.jpg';
import liabilityIcon from 'public/static/home/product-section/img/liability.jpg';
import lifeIcon from 'public/static/home/product-section/img/life.jpg';
import pensionIcon from 'public/static/home/product-section/img/pension.jpg';
import petIcon from 'public/static/home/product-section/img/pet.jpg';
import privateIcon from 'public/static/home/product-section/img/private.jpg';
import publicIcon from 'public/static/home/product-section/img/public.jpg';
import travelIcon from 'public/static/home/product-section/img/travel.jpg';

export type TAllowedVerticalGroups = 'health' | 'basics' | 'life';
export type TExtendedVerticalGroups = TAllowedVerticalGroups | 'company';

export const AllowedVerticalGroups = (
  t: TFunction
): Record<TAllowedVerticalGroups, string> =>
  ({
    health: t('home:productgrid.category.health', 'Health'),
    basics: t('home:productgrid.category.basics', 'Basics'),
    life: t('home:productgrid.category.life', 'Life'),
  } as const);

export type TVerticalGroupLookup = {
  title: string;
  key: TAllowedVerticalGroups;
  icon: StaticImageData;
  items: TVerticalLookup[];
};

export type TVerticalLookup = {
  key?: VerticalType;
  group: TAllowedVerticalGroups;
  title: string;
  shortTitle?: string;
  excerpt: string;
  icon: StaticImageData;
};

const iconList: Record<TAllowedVerticalGroups, StaticImageData> = {
  life: lifeCategoryIcon,
  health: healthCategoryIcon,
  basics: basicsCategoryIcon,
};

export const verticalsLookup = (
  t: TFunction
): Record<VerticalType, TVerticalLookup> => ({
  PUBLIC_HEALTH: {
    group: 'health',
    title: t('products.public.title', 'Public health'),
    shortTitle: t('products.public.title', 'Public health'),
    excerpt: t(
      'products.public.excerpt',
      'Choose from one of the four biggest public health providers'
    ),
    icon: publicIcon,
  },
  PRIVATE_HEALTH: {
    group: 'health',
    title: t('products.private.title', 'Private health'),
    shortTitle: t('products.private.title', 'Private health'),
    excerpt: t(
      'products.private.excerpt',
      'Comprehensive coverage for those who want a little extra'
    ),
    icon: privateIcon,
  },
  EXPAT_HEALTH: {
    group: 'health',
    title: t('products.expat.title', 'Expat health'),
    shortTitle: t('products.expat.title', 'Expat health'),
    excerpt: t(
      'products.expat.excerpt',
      'Health insurance when moving to a new country'
    ),
    icon: expatIcon,
  },
  TRAVEL: {
    group: 'health',
    shortTitle: t('products.travel.title.short', 'Travel health'),
    title: t('products.travel.title', 'Travel health insurance'),
    excerpt: t(
      'products.travel.excerpt',
      'Health coverage when traveling abroad'
    ),
    icon: travelIcon,
  },
  DENTAL: {
    group: 'health',
    title: t('products.dental.title', 'Dental insurance'),
    shortTitle: t('products.dental.title.short', 'Dental'),
    excerpt: t(
      'products.dental.excerpt',
      'Comprehensive dental coverage fills public health insurance gaps, offering treatments for preventive care, cleanings and more.'
    ),
    icon: dentalIcon,
  },
  LIABILITY: {
    group: 'basics',
    title: t('products.liability.title', 'Personal liability insurance'),
    shortTitle: t('products.liability.title.short', 'Personal liability'),
    excerpt: t(
      'products.liability.excerpt',
      'Protection for damage you cause to people or their belongings'
    ),
    icon: liabilityIcon,
  },
  HOUSEHOLD: {
    group: 'basics',
    shortTitle: t('products.household.title.short', 'Household'),
    title: t('products.household.title', 'Household contents insurance'),
    excerpt: t(
      'products.household.excerpt',
      'Protection for your home and your belongings'
    ),
    icon: householdIcon,
  },
  BIKE: {
    group: 'basics',
    shortTitle: t('products.bike.title.short', 'Bike'),
    title: t('products.bike.title', 'Bike insurance'),
    excerpt: t(
      'products.bike.excerpt',
      'Coverage for bikes in the case of theft, damages, and more'
    ),
    icon: bikeIcon,
  },
  DOG_LIABILITY: {
    group: 'basics',
    shortTitle: t('products.dog.title.short', 'Dog liability'),
    title: t('products.dog.title', 'Dog liability insurance'),
    excerpt: t(
      'products.dog.excerpt',
      'Covers damage caused by dogs to others or their belongings'
    ),
    icon: dogIcon,
  },
  LIFE: {
    group: 'life',
    shortTitle: t('products.life.title.short', 'Life'),
    title: t('products.life.title', 'Life insurance'),
    excerpt: t(
      'products.life.excerpt',
      'Support for your loved ones in the event of your passing'
    ),
    icon: lifeIcon,
  },
  DISABILITY: {
    group: 'life',
    shortTitle: t('products.disability.title.short', 'Disability'),
    title: t('products.disability.title', 'Disability insurance'),
    excerpt: t(
      'products.disability.excerpt',
      'Replaces lost income if you’re unable to work'
    ),
    icon: disabilityIcon,
  },
  LEGAL: {
    group: 'life',
    shortTitle: t('products.legal.title.short', 'Legal'),
    title: t('products.legal.title', 'Legal insurance'),
    excerpt: t('products.legal.excerpt', 'Protection for your legal rights'),
    icon: legalIcon,
  },
  PET_HEALTH: {
    group: 'basics',
    shortTitle: t('products.petHealth.title.short', 'Pet health'),
    title: t('products.petHealth.title', 'Pet health insurance'),
    excerpt: t(
      'products.petHealth.excerpt',
      'Coverage for vet appointments and treatments for pets'
    ),
    icon: petIcon,
  },
  PENSION: {
    group: 'life',
    shortTitle: t('products.pension.title.short', 'Pension'),
    title: t('products.pension.title', 'Pension insurance'),
    excerpt: t(
      'products.pension.excerpt',
      'Personal pension plan for a secured retirement'
    ),
    icon: pensionIcon,
  },
});

export const groupedVerticals = (t: TFunction): TVerticalGroupLookup[] =>
  (Object.keys(AllowedVerticalGroups(t)) as Array<TAllowedVerticalGroups>).map(
    (verticalGroupKey) => ({
      title: AllowedVerticalGroups(t)[verticalGroupKey],
      key: verticalGroupKey,
      icon: iconList[verticalGroupKey],
      items: (Object.keys(verticalsLookup(t)) as Array<VerticalType>)
        .map((key) => ({ key, ...verticalsLookup(t)[key] }))
        .filter((vertical) => vertical.group === verticalGroupKey),
    })
  );

export type TExtendedVerticalLookup = {
  key?: ExtendedVerticalType;
  group: TExtendedVerticalGroups;
  title: string;
  shortTitle?: string;
  excerpt: string;
  icon: StaticImageData;
};

export const extendedVerticalsLookup = (
  t: TFunction
): Record<ExtendedVerticalType, TExtendedVerticalLookup> => ({
  COMPANY_HEALTH: {
    group: 'company',
    title: t('products.companyhealth.title', 'Company health insurance'),
    shortTitle: t('products.companyhealth.title.short', 'Health'),
    excerpt: t(
      'products.companyhealth.excerpt',
      'Medical coverage for treatments and medications'
    ),
    icon: companyHealthIcon,
  },
  COMPANY_LIFE: {
    group: 'company',
    title: t('products.companylife.title', 'Company life insurance'),
    shortTitle: t('products.companylife.title.short', 'Life'),
    excerpt: t(
      'products.companylife.excerpt',
      'Support for your loved ones in the event of your passing'
    ),
    icon: lifeIcon,
  },
  COMPANY_DISABILITY: {
    group: 'company',
    title: t(
      'products.companydisability.title',
      'Company disability insurance'
    ),
    shortTitle: t('products.companydisability.title.short', 'Disability'),
    excerpt: t(
      'products.companydisability.excerpt',
      'Replaces lost income if you’re unable to work'
    ),
    icon: disabilityIcon,
  },
  COMPANY_PENSION: {
    group: 'company',
    title: t('products.companypension.title', 'Company pension insurance'),
    shortTitle: t('products.companypension.title.short', 'Pension'),
    excerpt: t(
      'products.companypension.excerpt',
      'Personal pension plan for a secured retirement'
    ),
    icon: companyPensionIcon,
  },
  COMPANY_TRAVEL: {
    group: 'company',
    title: t('products.companytravel.title', 'Company pension insurance'),
    shortTitle: t('products.companytravel.title.short', 'Travel'),
    excerpt: t(
      'products.companytravel.excerpt',
      'Health insurance coverage for those traveling for work'
    ),
    icon: companyTravelIcon,
  },
});
