import { RefObject } from 'react';

export const goToSection = (
  section: RefObject<HTMLDivElement> | null,
  navOffset?: number
) => {
  if (section?.current) {
    window.scrollTo({
      top: section.current.offsetTop - (navOffset || 0),
      behavior: 'smooth',
    });
  }
};
