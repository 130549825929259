import ReviewBadge from 'components/reviewBadge';
import { usePreviousState } from 'hooks/usePreviousState';
import { useWindowSize } from 'hooks/useWindowSize';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import chevronLeftIcon from 'public/static/icons/chevron-left.svg';
import chevronRightIcon from 'public/static/icons/chevron-right.svg';
import React, { useState } from 'react';

import ReviewDetails from './reviewDetails';
import styles from './style.module.scss';

const ReviewCarousel = ({
  reviewContent,
}: {
  reviewContent: ReviewCarouselProps;
}) => {
  const [carouselPage, setCarouselPage] = useState(0);
  const previousCarouselPage = usePreviousState(carouselPage);
  const screenWidth = useWindowSize();
  const { t } = useTranslation();

  const isBreakPoint = screenWidth && screenWidth > 1025;
  const isMobileBreakPoint = screenWidth && screenWidth < 544;
  const slideItemWidthMobile = screenWidth ? screenWidth - 64 : 326;

  const slideWidth = isMobileBreakPoint ? -slideItemWidthMobile : -304;

  const MIN_PAGE = 0;
  const MAX_PAGE = isMobileBreakPoint
    ? reviewContent.length - 1
    : reviewContent.length - 3;

  return (
    <section className="mt96">
      <div className="p-body">
        <header>
          <h2 className="p-h2 p--serif ta-center">
            {t('component.reviews.title', 'Don’t take our word for it')}
          </h2>
        </header>
        <div
          data-aos="animate-underline"
          data-aos-once="true"
          className={`mt8 ml-auto mr-auto ${styles.separator}`}
        />
        <div className={`mt24 w100 ${styles['carousel-container']}`}>
          {isBreakPoint && (
            <div className={styles['chevron-left-container']}>
              <button
                type="button"
                className={styles['chevron-button']}
                onClick={() => setCarouselPage(carouselPage - 1)}
                style={{
                  pointerEvents: carouselPage === MIN_PAGE ? 'none' : 'initial',
                  opacity: carouselPage === MIN_PAGE ? '0.2' : '1',
                }}
              >
                <Image
                  src={chevronLeftIcon}
                  alt="chevron left for moving review carousel to previous page"
                />
              </button>
            </div>
          )}
          <div className={`w100 ${styles['reviews-container']}`}>
            <div
              className={styles['reviews-inner-container']}
              style={
                previousCarouselPage !== carouselPage
                  ? {
                      transition: '0.4s ease-out',
                      transform: `translateX(${carouselPage * slideWidth}px)`,
                    }
                  : {}
              }
            >
              {reviewContent.map((item) => (
                <ReviewDetails
                  key={item.review}
                  title={item.title}
                  review={item.review}
                  consumer={item.consumer}
                  url={item.url}
                />
              ))}
            </div>
          </div>
          {isBreakPoint && (
            <div className={styles['chevron-right-container']}>
              <button
                type="button"
                className={styles['chevron-button']}
                onClick={() => setCarouselPage(carouselPage + 1)}
                style={{
                  pointerEvents: carouselPage === MAX_PAGE ? 'none' : 'initial',
                  opacity: carouselPage === MAX_PAGE ? '0.2' : '1',
                }}
              >
                <Image
                  src={chevronRightIcon}
                  alt="chevron right for moving review carousel to next page"
                />
              </button>
            </div>
          )}
        </div>
        {!isBreakPoint && (
          <div className={`mt24 ${styles['chevron-inner-container']}`}>
            <button
              type="button"
              className={styles['chevron-button']}
              onClick={() => setCarouselPage(carouselPage - 1)}
              style={{
                pointerEvents: carouselPage === MIN_PAGE ? 'none' : 'initial',
                opacity: carouselPage === MIN_PAGE ? '0.2' : '1',
              }}
            >
              <Image
                src={chevronLeftIcon}
                alt="chevron left for moving review carousel to previous page"
              />
            </button>
            <button
              type="button"
              className={`${styles['chevron-button']} ${styles['chevron-button--bottom']}`}
              onClick={() => setCarouselPage(carouselPage + 1)}
              style={{
                pointerEvents: carouselPage === MAX_PAGE ? 'none' : 'initial',
                opacity: carouselPage === MAX_PAGE ? '0.2' : '1',
              }}
            >
              <Image
                src={chevronRightIcon}
                alt="chevron right for moving review carousel to next page"
              />
            </button>
          </div>
        )}
      </div>
      <div className="d-flex jc-center mt48">
        <ReviewBadge className="jc-center" />
      </div>
    </section>
  );
};

export default ReviewCarousel;
