import { PrismicPreviewProps } from '@prismicio/next';
import { SliceZone } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types';
import MissingRequiredFields from 'components/MissingRequiredFields';
import { getLatestPostsByCategory } from 'endpoint';
import nextI18NextConfig from 'next-i18next.config.js';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import { createPrismicClient } from 'prismicio';
import { components } from 'slices';
import { i18nLocaleFormatter } from 'util/i18nLocaleFormatter';

import {
  ENGLISH_FRANCE_LOCALE_IDENTIFIER,
  ENGLISH_SPAIN_LOCALE_IDENTIFIER,
  FRENCH_FRANCE_LOCALE_IDENTIFIER,
  SPANISH_SPAIN_LOCALE_IDENTIFIER,
} from '../constants/i18n';
import { Homepage } from './homepage';
import styles from './style.module.scss';

declare global {
  interface Window {
    snowplow: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    Trustpilot: unknown;
    opera: string;
    Intercom?: Intercom_.IntercomCommand;
  }
}

export type BlogPostType = {
  node: {
    id: number;
    link: string;
    title: string;
    excerpt: string;
    featuredImage?: {
      node: {
        sourceUrl: string;
        altText: string;
      };
    };
  };
};

const localesWithCMSHomepage = new Set([
  ENGLISH_SPAIN_LOCALE_IDENTIFIER,
  SPANISH_SPAIN_LOCALE_IDENTIFIER,
  ENGLISH_FRANCE_LOCALE_IDENTIFIER,
  FRENCH_FRANCE_LOCALE_IDENTIFIER,
]);

const Home = ({
  blogPosts,
  navOffset,
  germanPaths,
  cmsHomepage,
}: {
  blogPosts: BlogPostType[];
  navOffset?: number;
  germanPaths: Array<string>;
  cmsHomepage?: PrismicDocument;
}) => {
  if (cmsHomepage) {
    const {
      seo_title: seoTitle,
      seo_description: seoDescription,
      og_description: ogDescription,
      og_image: ogImage,
      slices,
    } = cmsHomepage.data;

    if (
      !seoTitle ||
      !seoDescription ||
      !ogDescription ||
      Object.keys(ogImage).length === 0
    ) {
      return (
        <div className={`pt64 ${styles.container}`}>
          <MissingRequiredFields />
        </div>
      );
    }

    return (
      <>
        <NextSeo
          title={seoTitle}
          description={seoDescription}
          openGraph={{
            description: ogDescription,
            images: [
              {
                url: ogImage.url,
                height: ogImage.dimensions.height,
                width: ogImage.dimensions.width,
              },
            ],
          }}
        />
        <div className="pt64">
          <SliceZone slices={slices} components={components as never} />
        </div>
      </>
    );
  }

  /* If the current locale doesn't have a CMS-based homepage we render the hardcoded version */
  return (
    <>
      <NextSeo
        title="Health & Liability Insurance for Expats in Germany"
        description="Feather gives insurance advice for expats in Germany. Our digital insurance agent will help you get the right insurance policies for your needs."
      />
      <Homepage
        blogPosts={blogPosts}
        navOffset={navOffset}
        germanPaths={germanPaths}
      />
    </>
  );
};

export async function getStaticProps({
  locale,
  previewData,
}: {
  locale: string;
  previewData: PrismicPreviewProps;
}) {
  const blogPosts = await getLatestPostsByCategory();

  const hasCMSHomepage = localesWithCMSHomepage.has(locale);

  /* Iniziatilizing cmsHomepage as null as undefined cannot be serialized as JSON */
  let cmsHomepage: PrismicDocument | null = null;

  if (hasCMSHomepage) {
    cmsHomepage = await createPrismicClient({ previewData }).getSingle(
      'homepage',
      {
        lang: locale,
      }
    );
  }

  return {
    props: {
      blogPosts,
      cmsHomepage,
      ...(await serverSideTranslations(
        i18nLocaleFormatter(locale) || 'en-DE',
        ['common', 'home'],
        nextI18NextConfig
      )),
    },
  };
}

export default Home;
