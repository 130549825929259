import {
  ENGLISH_LOCALE_IDENTIFIER,
  GERMAN_LOCALE_IDENTIFIER,
} from 'constants/i18n';
import recomentationToolDE from 'public/static/home/cta-section/rectool-de.png';
import recomentationToolEN from 'public/static/home/cta-section/rectool-en.png';
import heroImageDE from 'public/static/home/hero-section/image-de.png';
import heroImageEN from 'public/static/home/hero-section/image-en.png';
import topSectionFirstImage from 'public/static/home/top-section/first-image.png';
import topSectionSecondImageDE from 'public/static/home/top-section/second-image-de.png';
import topSectionSecondImageEN from 'public/static/home/top-section/second-image-en.png';
import topSectionThirdImageDE from 'public/static/home/top-section/third-image-de.png';
import topSectionThirdImageEN from 'public/static/home/top-section/third-image-en.png';

const images = {
  hero: {
    [ENGLISH_LOCALE_IDENTIFIER]: heroImageEN,
    [GERMAN_LOCALE_IDENTIFIER]: heroImageDE,
  },
  topFirst: {
    [ENGLISH_LOCALE_IDENTIFIER]: topSectionFirstImage,
    [GERMAN_LOCALE_IDENTIFIER]: topSectionFirstImage,
  },
  topSecond: {
    [ENGLISH_LOCALE_IDENTIFIER]: topSectionSecondImageEN,
    [GERMAN_LOCALE_IDENTIFIER]: topSectionSecondImageDE,
  },
  topThird: {
    [ENGLISH_LOCALE_IDENTIFIER]: topSectionThirdImageEN,
    [GERMAN_LOCALE_IDENTIFIER]: topSectionThirdImageDE,
  },
  recTool: {
    [ENGLISH_LOCALE_IDENTIFIER]: recomentationToolEN,
    [GERMAN_LOCALE_IDENTIFIER]: recomentationToolDE,
  },
};

export default images;
