import { TFunction } from 'next-i18next';

export interface ReviewContent {
  title: string;
  review: string;
  consumer: string;
  url: string;
}

export const getReviews = (t: TFunction): ReviewContent[] => [
  {
    title: t('component.reviewcarousel.tile.1.title'),
    review: t('component.reviewcarousel.tile.1.review'),
    consumer: t('component.reviewcarousel.tile.1.consumer'),
    url: t('component.reviewcarousel.tile.1.url'),
  },
  {
    title: t('component.reviewcarousel.tile.2.title'),
    review: t('component.reviewcarousel.tile.2.review'),
    consumer: t('component.reviewcarousel.tile.2.consumer'),
    url: t('component.reviewcarousel.tile.2.url'),
  },
  {
    title: t('component.reviewcarousel.tile.3.title'),
    review: t('component.reviewcarousel.tile.3.review'),
    consumer: t('component.reviewcarousel.tile.3.consumer'),
    url: t('component.reviewcarousel.tile.3.url'),
  },
  {
    title: t('component.reviewcarousel.tile.4.title'),
    review: t('component.reviewcarousel.tile.4.review'),
    consumer: t('component.reviewcarousel.tile.4.consumer'),
    url: t('component.reviewcarousel.tile.4.url'),
  },
  {
    title: t('component.reviewcarousel.tile.5.title'),
    review: t('component.reviewcarousel.tile.5.review'),
    consumer: t('component.reviewcarousel.tile.5.consumer'),
    url: t('component.reviewcarousel.tile.5.url'),
  },
  {
    title: t('component.reviewcarousel.tile.6.title'),
    review: t('component.reviewcarousel.tile.6.review'),
    consumer: t('component.reviewcarousel.tile.6.consumer'),
    url: t('component.reviewcarousel.tile.6.url'),
  },
];
